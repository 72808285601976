<template>
  <div id="app">
    <app-navigation></app-navigation>
    <app-header></app-header>
  </div>
</template>

<script>

import NavigationBar from './components/Navigation.vue'

export default {
  components: {
    'app-navigation': NavigationBar,
    }

}
</script>

<style scoped>


</style>
