<template>
  <div class="grid">
    <div class="title">
      <h3>Välkommen</h3>
    </div>
    <div class="center">
      <div class="padding">
        <b-button v-b-toggle.collapse-101 variant="primary" class="buttonWidth"
          >Smårätter</b-button
        >

        <b-collapse id="collapse-101" class="mt-2">
          <b-card>
            <b-button v-b-toggle.collapse-101-inner size="sm"
              >Edamamebönor med chili och vitlök</b-button
            >
            <b-collapse id="collapse-101-inner" class="mt-2">
              <div
                v-for="(ingredients, index) in Edamamebeans"
                :key="index"
                :bind="{ 'is-selected': ingredients.status }"
              >
                {{ ingredients }}
              </div>
              <hr />
              <ol v-for="(howTo, index) in EdamamebeansHowTo" :key="index">
                {{
                  index + 1
                }}:
                {{
                  howTo
                }}
              </ol>
            </b-collapse>
          </b-card>
        </b-collapse>
        <b-collapse id="collapse-101" class="mt-2"> </b-collapse>

        <div class="padding">
          <b-button
            v-b-toggle.collapse-102
            variant="primary"
            class="buttonWidth"
            >sås/röror</b-button
          >
          <b-collapse id="collapse-102" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-102-inner size="sm"
                >Tomatsås</b-button
              >
              <b-collapse id="collapse-102-inner" class="mt-2">
                <div
                  v-for="(ingredients, index) in tomatoSauce"
                  :key="index"
                  :bind="{ 'is-selected': ingredients.status }"
                >
                  {{ ingredients }}
                </div>
                <hr />
                <ol v-for="(howTo, index) in tomatoSauceHowTo" :key="index">
                  {{
                    index + 1
                  }}:
                  {{
                    howTo
                  }}
                </ol>
              </b-collapse>
            </b-card>
          </b-collapse>
          <b-collapse id="collapse-102" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-103-inner size="sm"
                >Tzatziki</b-button
              >
              <b-collapse id="collapse-103-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in Tzatziki"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in TzatzikiHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-102" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-204-inner size="sm"
                >Guacamole</b-button
              >
              <b-collapse id="collapse-204-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in Guacamole"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in GuacamoleHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-102" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-207-inner size="sm"
                >Pepparsås</b-button
              >
              <b-collapse id="collapse-207-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in pepparSauce"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in pepparSauceHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>



          <b-collapse id="collapse-102" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-206-inner size="sm"
                >Gräddsås</b-button
              >
              <b-collapse id="collapse-206-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in graddSauce"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in graddSauceHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>


        </div>

        <div class="padding">
          <b-button
            v-b-toggle.collapse-103
            variant="primary"
            class="buttonWidth"
            >Kött</b-button
          >
          <b-collapse id="collapse-103" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-103-inner size="sm"
                >Älggryta</b-button
              >
              <b-collapse id="collapse-103-inner" class="mt-2">
                <div
                  v-for="(ingredients, index) in algGryta"
                  :key="index"
                  :bind="{ 'is-selected': ingredients.status }"
                >
                  {{ ingredients }}
                </div>
                <hr />
                <ol v-for="(howTo, index) in algGrytaHowTo" :key="index">
                  {{
                    index + 1
                  }}:
                  {{
                    howTo
                  }}
                </ol>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-103" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-104-inner size="sm"
                >Stekt ankbröst med apelsinsås och ugnsstekt potatis
              </b-button>
              <b-collapse id="collapse-104-inner" class="mt-2">
                <div
                  v-for="(ingredients, index) in duck"
                  :key="index"
                  :bind="{ 'is-selected': ingredients.status }"
                >
                  {{ ingredients }}
                </div>
                <hr />
                <ol v-for="(howTo, index) in duckHowTo" :key="index">
                  {{
                    index + 1
                  }}:
                  {{
                    howTo
                  }}
                </ol>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-103" class="mt-2"> </b-collapse>
        </div>

  <div class="padding">
          <b-button
            v-b-toggle.collapse-1031
            variant="primary"
            class="buttonWidth"
            >Potatis</b-button
          >
          <b-collapse id="collapse-1031" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-103-inner size="sm"
                >Potatiskaka</b-button
              >
              <b-collapse id="collapse-103-inner" class="mt-2">
                <div
                  v-for="(ingredients, index) in potatoCake"
                  :key="index"
                  :bind="{ 'is-selected': ingredients.status }"
                >
                  {{ ingredients }}
                </div>
                <hr />
                <ol v-for="(howTo, index) in potatoCakeHowTo" :key="index">
                  {{
                    index + 1
                  }}:
                  {{
                    howTo
                  }}
                </ol>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-1031" class="mt-2"> </b-collapse>
        </div>

        <div class="padding">
          <b-button
            v-b-toggle.collapse-104
            variant="primary"
            class="buttonWidth"
            >Fisk</b-button
          >
          <b-collapse id="collapse-104" class="mt-2"> </b-collapse>
        </div>

        <div class="padding">
          <b-button
            v-b-toggle.collapse-106
            variant="primary"
            class="buttonWidth"
            >Skaldjur</b-button
          >

          <b-collapse id="collapse-106" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-106-inner size="sm"
                >Bacon- och kräftsås</b-button
              >
              <b-collapse id="collapse-106-inner" class="mt-2">
                <div
                  v-for="(ingredients, index) in baconAndKraftSause"
                  :key="index"
                  :bind="{ 'is-selected': ingredients.status }"
                >
                  {{ ingredients }}
                </div>
                <hr />
                <ol
                  v-for="(howTo, index) in baconAndKraftSauseHowTo"
                  :key="index"
                >
                  {{
                    index + 1
                  }}:
                  {{
                    howTo
                  }}
                </ol>
              </b-collapse>
            </b-card>
          </b-collapse>
          <b-collapse id="collapse-106" class="mt-2"> </b-collapse>
        </div>

        <b-collapse id="collapse-106" class="mt-2">
          <b-card>
            <b-button v-b-toggle.collapse-107-inner size="sm"
              >Skaldjurslasagne med saffran</b-button
            >
            <b-collapse id="collapse-107-inner" class="mt-2">
              <div
                v-for="(ingredients, index) in skaldjurslasagne"
                :key="index"
                :bind="{ 'is-selected': ingredients.status }"
              >
                {{ ingredients }}
              </div>
              <hr />
              <ol v-for="(howTo, index) in skaldjurslasagneHowTo" :key="index">
                {{
                  index + 1
                }}:
                {{
                  howTo
                }}
              </ol>
            </b-collapse>
          </b-card>
        </b-collapse>
        <b-collapse id="collapse-107" class="mt-2"> </b-collapse>

        <div class="padding">
          <b-button
            v-b-toggle.collapse-107
            variant="primary"
            class="buttonWidth"
            >Vegetariskt</b-button
          >
          <b-collapse id="collapse-107" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-107-inner size="sm"
                >Champinjonpasta</b-button
              >
              <b-collapse id="collapse-107-inner" class="mt-2">
                <div
                  v-for="(ingredients, index) in champinjonpasta"
                  :key="index"
                  :bind="{ 'is-selected': ingredients.status }"
                >
                  {{ ingredients }}
                </div>
                <hr />
                <ol v-for="(howTo, index) in champinjonpastaHowTo" :key="index">
                  {{
                    index + 1
                  }}:
                  {{
                    howTo
                  }}
                </ol>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-107" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-108-inner size="sm"
                >Tarte flambée med sparris och salladslök</b-button
              >
              <b-collapse id="collapse-108-inner" class="mt-2">
                <div
                  v-for="(ingredients, index) in tarteFlambee"
                  :key="index"
                  :bind="{ 'is-selected': ingredients.status }"
                >
                  {{ ingredients }}
                </div>
                <hr />
                <ol v-for="(howTo, index) in tarteFlambeeHowTo" :key="index">
                  {{
                    index + 1
                  }}:
                  {{
                    howTo
                  }}
                </ol>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-107" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-109-inner size="sm"
                >Crème Vichyssoise</b-button
              >
              <b-collapse id="collapse-109-inner" class="mt-2">
                <div
                  v-for="(ingredients, index) in Vichyssoise"
                  :key="index"
                  :bind="{ 'is-selected': ingredients.status }"
                >
                  {{ ingredients }}
                </div>
                <hr />
                <ol v-for="(howTo, index) in VichyssoiseHowTo" :key="index">
                  {{
                    index + 1
                  }}:
                  {{
                    howTo
                  }}
                </ol>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-108" class="mt-2"> </b-collapse>
        </div>

        <div class="padding">
          <b-button
            v-b-toggle.collapse-108
            variant="primary"
            class="buttonWidth"
            >Efterrätt</b-button
          >
          <b-collapse id="collapse-108" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-108-inner size="sm"
                >Créme brulée
              </b-button>
              <b-collapse id="collapse-108-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in cremeBrulee"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in cremeBruleeHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>
          <b-collapse id="collapse-108" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-202-inner size="sm"
                >Chokladsås</b-button
              >
              <b-collapse id="collapse-202-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in choclateSauce"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in choclateSauceHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-108" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-203-inner size="sm"
                >Kolasås</b-button
              >
              <b-collapse id="collapse-203-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in kolaSauce"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in kolaSauceHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>

          <b-collapse id="collapse-108" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-204-inner size="sm"
                >Lyxig rabarberkräm med jordgubbar & vaniljstång</b-button
              >
              <b-collapse id="collapse-204-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in rabarberCreame"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol
                    v-for="(howTo, index) in rabarberCreameHowTo"
                    :key="index"
                  >
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>

            <b-card>
              <b-button v-b-toggle.collapse-205-inner size="sm"
                >Broas de Mel
              </b-button>
              <b-collapse id="collapse-205-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in broasDeMel"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in broasDeMelHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>

           <b-card>
              <b-button v-b-toggle.collapse-206-inner size="sm"
                >Choklad sockerlag
              </b-button>
              <b-collapse id="collapse-206-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in choclateSyrap"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in choclateSyrapHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>

          </b-collapse>

          <b-collapse id="collapse-108" class="mt-2"> </b-collapse>
        </div>

        <div class="padding">
          <b-button
            v-b-toggle.collapse-109
            variant="primary"
            class="buttonWidth"
            >Bakat</b-button
          >

          <b-collapse id="collapse-109" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-109-inner size="sm"
                >Hallonpavlova</b-button
              >
              <b-collapse id="collapse-109-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in hallonpavlova"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in hallonpavlovaHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>
          <b-collapse id="collapse-109" class="mt-2"> </b-collapse>
        </div>

        <div class="padding">
          <b-button v-b-toggle.collapse-4 variant="primary" class="buttonWidth"
            >Drinkar</b-button
          >
          <b-collapse id="collapse-4" class="mt-2">
            <b-card>
              <b-button v-b-toggle.collapse-2-inner size="sm"
                >Old fashioned</b-button
              >
              <b-collapse id="collapse-2-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in oldFashioned"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in oldFashionedHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>

            <b-card>
              <b-button v-b-toggle.collapse-3-inner size="sm"
                >Espresso Martini</b-button
              >
              <b-collapse id="collapse-3-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in espressoMartini"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol
                    v-for="(howTo, index) in espressoMartiniHowTo"
                    :key="index"
                  >
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>

            <b-card>
              <b-button v-b-toggle.collapse-4-inner size="sm"
                >Bahama Mama</b-button
              >
              <b-collapse id="collapse-4-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in bahamaMama"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in bahamaMamaHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>

            <b-card>
              <b-button v-b-toggle.collapse-5-inner size="sm"
                >White russian longdrink</b-button
              >
              <b-collapse id="collapse-5-inner" class="mt-2">
                <b-card>
                  <div
                    v-for="(ingredients, index) in whiteRussian"
                    :key="index"
                    :bind="{ 'is-selected': ingredients.status }"
                  >
                    {{ ingredients }}
                  </div>
                  <hr />
                  <ol v-for="(howTo, index) in whiteRussianHowTo" :key="index">
                    {{
                      index + 1
                    }}:
                    {{
                      howTo
                    }}
                  </ol>
                </b-card>
              </b-collapse>
            </b-card>

              <b-card>
                <b-button v-b-toggle.collapse-6-inner size="sm"
                  >Choklad Espresso Martini</b-button>
                <b-collapse id="collapse-6-inner" class="mt-2">
                  <b-card>
                    <div
                      v-for="(ingredients, index) in choclateEspressoMartinti"
                      :key="index"
                      :bind="{ 'is-selected': ingredients.status }"
                    >
                      {{ ingredients }}
                    </div>
                    <hr />
                    <ol v-for="(howTo, index) in choclateEspressoMartintiHowTo" :key="index">
                      {{
                        index + 1
                      }}:
                      {{
                        howTo
                      }}
                    </ol>
                  </b-card>
                </b-collapse>
              </b-card>

                            <b-card>
                <b-button v-b-toggle.collapse-7-inner size="sm"
                  >Tom Collins</b-button>
                <b-collapse id="collapse-7-inner" class="mt-2">
                  <b-card>
                    <div
                      v-for="(ingredients, index) in tomCollins"
                      :key="index"
                      :bind="{ 'is-selected': ingredients.status }"
                    >
                      {{ ingredients }}
                    </div>
                    <hr />
                    <ol v-for="(howTo, index) in tomCollinsHowTo" :key="index">
                      {{
                        index + 1
                      }}:
                      {{
                        howTo
                      }}
                    </ol>
                  </b-card>
                </b-collapse>
              </b-card>

          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    setUserChoice(choice) {
      this.userChoice = choice.target.innerText;
    },
  },
  data: function () {
    return {
      userChoice: "Recept",
      oldFashioned: ["5 cl Bourbon", "2 cl Sockerlag", "4 stänk Bitters"],
      oldFashionedHowTo: [
        "Blanda alla ingredienser i ett rörglas",
        "Häll i is",
        "Rör i ca 30-60 sekunder",
      ],
      espressoMartini: [
        "3 cl Vodka",
        "3 cl Kaffelikör",
        "6 cl Espresso/starkt kaffe",
      ],
      espressoMartiniHowTo: [
        "Blanda alla ingredienser i en shaker",
        "Häll på med is",
        "Skaka och servera",
      ],
      bahamaMama: [
        "6 cl Kokoslikör",
        "6 cl Ananasjuice",
        "6 cl Apelsinjuice",
        "2 cl citronjuice",
        "2 cl grenadin",
      ],
      bahamaMamaHowTo: [
        "Krossa is och häll upp i ett glas",
        "Blanda alla ingredienser i en shaker med is och skaka våldsamt",
        "Häll upp",
      ],
      whiteRussian: ["4 cl kaffelikör", "6 cl vodka", "12 cl mjölk"],
      whiteRussianHowTo: [
        "Blanda alla ingredienser i en shaker med is och skaka våldsamt",
        "servera i ett glas fyllt med is",
      ],
      champinjonpasta: [
        "500 g champinjoner",
        "5 dl grädde",
        "1 schalottenlök",
        "Vitlök",
        "1,5 msk Soja",
        "1,5 tsk Dijonsenap",
        "pasta",
        "parmesanost",
        "Olivolja med smak av vit tryffel",
      ],
      champinjonpastaHowTo: [
        "Hacka champinjoner, schalottenlök och krossa vitlöken",
        "Stek champinjonerna i en torr stekpanna och lägg åt sidan",
        "Stek lök och vitlök i samma panna tillsammans med olja",
        "Tillsätt grädde, dijon, soja krydda med salt och peppar",
        "Koka upp under omrörning, tillsätt champinjoner och låt stå",
        "Koka pastan",
        "Häll i pastan och parmesanost i såsen och servera",
      ],
      algGryta: [
        "500 g älgkött",
        "Smör",
        "6 enbär",
        "Köttfond",
        "0,5 msk Soja",
        "1 paket fryta kantareller",
        "2 dl créme fraiche",
        "Majsstärkelse",
      ],
      algGrytaHowTo: [
        "Bryn köttet",
        "Häll köttet, krossade enbär och köttfond+vatten i en gryta och låt koka sakta i minst 1 timme, gärna 3",
        "Häll i créme fraiche och koka i 30 minuter",
        "Häll i kantarellerna och låt stå i 15 minuter",
        "Servera med någon form av potatis",
      ],
      tomatoSauce: [
        "1 Gul lök",
        "2-4 Vitlöksklyfta",
        "4 paket Krossade tomater med vitklök och basilika",
        "3 msk Tomatpure",
      ],
      tomatoSauceHowTo: [
        "Finhacka lök och krosa vitlöken",
        "Fräs lök och vitlök i en kastrull",
        "Häll i krossade tomater, tomatpure, salt och peppar",
        "Koka på svag värme i minst 30 minuter",
      ],
      choclateSauce: [
        "1 msk smör",
        "1,5 msk kakao",
        "1 msk sirap",
        "3 msk socker",
        "1 dl grädde",
      ],
      choclateSauceHowTo: [
        "Smält smöret och vispa i kakao. Tillsätt sirapm socker och grädde",
        "Koka upp och låt koka 30-60 sekunder, ju längre koktid desto tjockare sås",
      ],
      Tzatziki: [
        "1 gurka",
        "1 paket turkisk yogurt",
        "Vitlök",
        "Vitlökspulver",
      ],
      TzatzikiHowTo: [
        "Riv gurkan och häll den i sil, tryck ut så mycket vätska du klarar av och salta. Krossa önskad mängd vitlök",
        "Blanda yogurt, gurka, vitlök, salt+peppar och vitlökspulver. Låt gärna vila en stund innan servering",
      ],
      Guacamole: [
        "Så många avokados du har råd med, vanligtvis 1-2",
        "1 paket Turkisk yogurt",
        "Vitlök",
        "Vitlökspulver",
      ],
      GuacamoleHowTo: [
        "Ta ur köttet ur avokadon, krossa med en gaffel om den inte är för hård använd då mixer",
        "Blanda avokado, turkisk yogurt, vitlök, vitlökspulver och salt+peppar",
        "Om den ska användas dagen efter häll i lite lime juice för att den ska hålla sig fräsch",
      ],
      cremeBrulee: [
        "1 vaniljstång",
        "5 dl vispgrädde",
        "1,5 dl mjölk",
        "7 äggulor",
        "1,5 dl strösocker",
        "5 msk råsocker",
      ],
      cremeBruleeHowTo: [
        "Sätt ugnen på 150 grader och dela upp vaniljstången och skrapa ur fröna",
        "Koka upp vaniljfrön, vaniljstång, grädde och mjölk. Ta bort från värmen och låt dra i ca 10 minuter",
        "Rör ihop äggulor med socker, blanda med gräddmjölken",
        "Dela upp smeten i portionsformar, ställ formarna i en ugnsform och fyll sedan med vatten",
        "Ställ formen i ugnen och grädda tills de blivit fasta i konsistensen, ca 40 minuter. Testa genom att skaka lite på en form och se om den rör sig. Lät svalna och ställ i kylskåpet",
        "Häll på råsocker och brän av med brännare",
      ],
      Vichyssoise: [
        "4-5 potatisar, ca 400 g",
        "400 g purjolök",
        "75 g smör (margarin)",
        "1 hönsbuljong (eller grönsaksbuljong)",
        "1 tsk timjan",
        "2,5 dl gräddmjölk",
        "salt",
        "peppar",
        "1 knippe gräslök",
      ],
      VichyssoiseHowTo: [
        "Skala och skölj potatisen. Ansa och skölj purjolöken, endast den ljusa delen ska användas. Skär potatis och purjolök i bitar.",
        "Smält smöret i en kastrull. Lägg i purjolöken och låt den fräsa under lock tills den är mjuk, utan att ta färg. Lägg i potatisen, tillsätt timjan, salt och nymalen vitpeppar, lägg på lock och låt fräsa ytterligare några minuter.",
        "Tillsätt hälften av buljongen och låt sjuda på svag värme i 30–40 minuter.",
        "Kör grönsakskoket till puré i matberedare eller mixer.",
        "Häll tillbaka purén i kastrullen. Tillsätt resten av buljongen samt gräddmjölken och värm långsamt upp till kokpunkten. Smaka av och spä med mer gräddmjölk om det behövs. Hacka gräslöken.",
        "Servera soppan varm eller kall, överströdd med hackad gräslök.",
      ],
      kolaSauce: [
        "2,5 dlvispgrädde",
        "3 mskströsocker",
        "0,5 dlljus sirap",
        "1 msksmör",
        "1 tsksalt, knappt",
      ],
      kolaSauceHowTo: [
        "Blanda grädde, socker och sirap i en tjockbottnad och vid kastrull. Koka upp under omrörning. Fortsätt att koka utan lock på medelvärme tills såsen fått en fin gyllene färg och tjocknat lite, cirka tio minuter.",
        "Ta kastrullen från värmen och rör i smör och salt. Låt svalna lite och häll över i en värmetålig skål så svalnar den fortare. Servera såsen rumstempererad.",
      ],
      rabarberCreame: [
        "400 g rabarber",
        "500 g jordgubbar",
        "2-3 dl strösocker",
        "5 dl vatten",
        "1 st vaniljstång",
        "3 msk potatismjöl",
        "mjölk- eller grädde",
      ],
      rabarberCreameHowTo: [
        "Skär rabarbern i bitar och stora jordgubbar i halvor. Lägg rabarber och jordgubbar i en kastrull tillsammans med socker och vatten.",
        "Dela vaniljstången på längden och skrapa ur det mesta av fröna. Tillsätt vaniljstång och frön till kastrullen.",
        "Låt alltsammans koka tills rabarbern är mjuk, det tar 10-15 minuter.",
        "Dra av kastrullen från värmen. Blanda potatismjöl och vatten till en redning och häll den i en fin stråle samtidigt som du rör.",
        "Sätt tillbaka kastrullen på plattan och låt krämen få ett uppkok. Servera krämen kall eller ljummen med mjölk eller grädde.",
      ],
      hallonpavlova: [
        "6 äggvitor",
        "3,25 dl strösocker",
        "3 msk kakao",
        "1 tsk balsamvinäger",
        "50 g mörk choklad, finhackad",
        "5 dl vispgrädde",
        "500 g hallon",
        "2 msk mörk choklad, grovriven",
      ],
      hallonpavlovaHowTo: [
        "Värm ugnen till 180°. Klä en plåt med bakplåtspapper. Rita mitt på pappret en cirkel på cirka 23 cm i diameter.",
        "Vispa sedan äggvitorna tills de börjar bilda toppar. Tillsätt sockret, lite i taget, under fortsatt vispning tills marängen är styv och glansig. Häll ned den siktade kakaon och den hackade chokladen. Tillsätt också vinägern och vänd ned alltsammans tills chokladen har blandat sig helt med smeten.",
        "Bred ut smeten i cirkeln på bakplåtspappret och stryk den jämn. Ställ in plåten i ugnen och sänk temperaturen till 150°. Grädda i cirka 1 timme och 15 minuter. Stäng av ugnen, glänta på luckan och låt plåten stå kvar tills marängen helt har svalnat.",
        "När det är dags att servera stjälper du upp marängen på ett stort fat. Vispa grädden luftig och bred ut den på toppen av marängen. Arrangera hallonen ovanpå grädden och hyvla chokladspån över hallonen. Ät och njut.",
      ],
      baconAndKraftSause: [
        "2 st bacon- paket (à 140 g)",
        "1 burk kräftstjärtar i lake",
        "12 körsbärstomater- ( ca 350 g)",
        "150 g champinjoner",
        "0,5 st chili- t ex spansk peppar röd",
        "1 knippe persilja",
        "1 msk smör",
        "1 tsk honung",
        "150 g spenat",
        "5 dl grädde- lätt (5 %)",
        "1 st grönsaksbuljongstärning- gärna med persilja och vitlök",
        "1 dl parmesan- riven",
        "1 krm svartpeppar- nymald",
        "1 msk majsstärkelse (Maizena)",
        "pasta",
      ],
      baconAndKraftSauseHowTo: [
        "Strimla baconet och stek det knaprigt. Låt rinna av på hushållspapper. Låt kräftstjärtarna rinna av.",
        "Ansa tomaterna. Ansa och halvera champinjonerna. Kärna ur och strimla chilin. Hacka persiljan.",
        "Fräs tomater och svamp i smör ca 3 min. Tillsätt vinäger och honung och fräs ytterligare 1 minut.",
        "Blanda ner chili, persilja och spenat och häll över grädden. Smula i buljongtärningen och låt puttra ca 5 min.",
        "Vänd i bacon, kräftstjärtar och parmesan. Smaka av med salt och peppar. Rör ut majsstärkelsen i lite kallt vatten. Blanda  redningen i såsen och koka upp. Servera såsen till nykokt pasta.",
      ],
      Edamamebeans: [
        "250 g frysta edamamebönor i sitt skal",
        "2 vitlöksklyftor",
        "1 msk riven färsk ingefära",
        "1 tsk chiliflakes",
        "1 msk sesamolja",
        "1-2 tsk flingsalt",
      ],
      EdamamebeansHowTo: [
        " Koka bönorna i lättsaltat vatten i ca 3 minuter. Häll av bönorna.",
        " Hetta upp lite sesamolja och olivolja i en stekpanna och ha i pressad vitlök, ingefära och chiliflakes",
        "  Låt fräsa 1 minut och tillsätt sedan bönorna och låt fräsa en minut till. Blanda om ordentligt och lägg sedan upp i en skål. Krydda med flingsalt.",
      ],
      skaldjurslasagne: [
        "250 g lasagneplattor- färska",
        "6 salladslökar",
        "150 g spenat",
        "2 pkt saffran- (0,5 g)",
        "0,5 tsk chiliflakes",
        "1 msk smör",
        "250 g laxfilé- skinn- och benfri",
        "0,5 tsk salt",
        "125 g mozzarella- riven",

        "1 schalottenlök",
        "1 vitlöksklyfta",
        "1 msk smör",
        "3 dl vitt vin- torrt",
        "2 dl vispgrädde",
        "1 dl vatten",
        "2 msk fiskfond- konc",
        "1,5 msk majsstärkelse (Maizena)- Maizena",
        "0,5 tsk salt",
        "1 burk räkor- stora i lake (400 g)",
        "1 burk kräftstjärtar- i lake (400 g)",
        "0,5 dl dill- hackad",
        "1 fänkålsstånd- litet",
        "2 msk olivolja",
        "1 msk citron- pressad",
        "1 krm salt",
        "1 påse sallad- blandad (65 g)",
        "dillkvistar",
        "citronskivor",
      ],
      skaldjurslasagneHowTo: [
        "Sås: Sätt ugnen på 200°. Skala och finhacka lök och vitlök. Fräs löken i smör i en kastrull ca 3 min. Tillsätt vin, grädde, vatten och fond, sjud ca 10 min. Sila. Red av med majsstärkelsen utrörd i lite vatten. Låt koka upp. Krydda med salt.",
        "Låt räkor och kräftstjärtar rinna av väl. Blanda ner 2/3 i såsen (spara resten till garnering). Tillsätt dillen.",
        "Ansa och skiva salladslöken tunt. Fräs lök, spenat, saffran och chiliflakes i smör ca 3 min.",
        "Skiva laxen tunt och strö på salt. Varva sås, plattor, spenatröra och lax i en smord ugnssäker form. Avsluta med sås. Strö på ost. Sätt in formen mitt i ugnen och gratinera ca 30 min.",
        "Sallad: Ansa och finstrimla fänkålen, blanda med olja, citronsaft och salt. Blanda i salladen.",
        "Lägg resten av skaldjuren på lasagnen. Garnera med dill, citronskivor och några salladsblad. Servera med fänkålssallad.",
      ],

      duck: [
        "600 – 700 g ankbröst",
        "1 tsk salt",
        "1 krm nymald svartpeppar",
        "2 schalottenlökar",
        "0,5 apelsin",
        "1 msk smör- + 1 msk",
        "2 stjärnanis",
        "1 lagerblad",
        "10 svartpepparkorn",
        "1,5 dl rött portvin",
        "1 dl vatten",
        "2 msk konc apelsinjuice",
        "2 msk konc kalvfond",
        "1 tsk majsstärkelse (Maizena)",
        "800 g mandelpotatis",
        "1 dl parmesanost- riven eller annan vällagrad ost",
        "0,5 tsk salt",
        "1 apelsin",
        "grönkål",
      ],
      duckHowTo: [
        "Sätt ugnen på 200°. Snitta ett rutmönster i skinnet på ankbrösten och salta och peppra dem.",
        "Sås: Skala och skiva schalottenlöken. Skölj apelsinen noga i ljummet vatten. Skala av det yttersta skalet med en potatisskalare. Skär skalet i tunna strimlor. ",
        "Stek löken i 1 msk smör och lägg ner apelsinskal och kryddor. Häll på portvin och vatten och tillsätt apelsinjuice och fond. Sjud såsen ca 15 min. Sila bort kryddorna. Red av med majsstärkelse utrört i 2 msk vatten.",
        "Potatis: Skölj potatisen och skär dem i halvor. Blanda dem med ost och salt på en smord plåt eller en plåt täckt med bakplåtspapper. Tillaga mitt i ugnen ca 20 min tills potatisen är mjuk och osten har fått färg.",
        "Lägg ankbrösten med skinnsidan nedåt i en kall stekpanna och sätt på högsta värmen. Stek dem ca 6 min. Vänd och stek på köttsidan 2–3 min.",
        "Lägg ankbrösten med skinnsidan uppåt i en ugnssäker form, om du inte har en stekpanna som tål ugnsvärme. Tillaga mitt i ugnen 8–10 min till 65° Innertemperatur. Låt ankbrösten vila ca 10 min innan de serveras.",
        "Tillbehör: Skala och skär ut hinnfria klyftor ur apelsinen. Förväll grönkålen i kokande lättsaltat vatten ca 1 minut. Låt grönkålen rinna av väl. Stek apelsinklyftor och grönkål i lite av fettet från ankbrösten.",
        "Vispa ner 1 msk smör i såsen och servera den till anka och tillbehör.",
      ],
      tarteFlambee: [
        "3 dl vatten",
        "50 g jäst",
        "1 tsk salt",
        "1 tsk strösocker",
        "1 msk olivolja",
        "7 dl vetemjöl special- (420 g)",
        "1 knippa salladslök",
        "250 g grön sparris",
        "0,5 citron",
        "2 dl crème fraiche",
        "2 dl parmesan- grovriven",
        "0,5 kruka friséesallad- liten",
        "2 msk olivolja",
        "2 tsk flingsalt",
        "2 krm svartpeppar- nymald",
      ],
      tarteFlambeeHowTo: [
        "Värm vattnet till 37°. Rör ut jästen i vattnet. Tillsätt salt, socker, olja och nästan allt mjöl (spara lite till utbakning). Arbeta degen tills den är slät och smidig, 5–10 min. Jäs övertäckt ca 40 min.",
        "Sätt ugnen på 250°. Rulla fyra bollar av degen, lägg på mjölad arbetsbänk. Kavla ut rundlar, ca 20 cm i diameter. Lägg på plåtar med bakplåtspapper.",
        "Strimla salladslöken. Skär bort det nedersta på sparrisen. Skölj citronen noga i ljummet vatten. Riv skalet. Blanda crème fraiche och citronskal. Fördela det på bottnarna. Lägg på sparris, salladslök och parmesanost. Grädda mitt i ugnen ca 10 min.",
        "Garnera med friséesallad och ringla över olja. Krydda med flingsalt och peppar. Servera genast.",
      ],

      broasDeMel: [
        "750 grams flour",
        "560 grams sugar",
        "2 eggs",
        "250 grams sugarcane honey also called molasses",
        "35 grams ground cinnamon",
        "5 grams ground cloves",
        "15 grams ground nutmeg",
        "25 grams Baking Soda",
        "Zest of 1/2 lemon",
        "95 grams Butter",
        "95 grams lard (which is pork fat) – these 95 grams can of course be replaced with 95 gr. of butter",
        "Almonds (possible addition and finishing touch)",
      ],
      broasDeMelHowTo: [
        "Mix all the ingredients (except the eggs and sugar cane sugar).",
        "Once mixed, make a hole in the middle and add the eggs and the molasses / sugar cane sugar.",
        "Knead the down again and again until it becomes smooth and no longer sticks to the bowl.",
        "Leave in the fridge for 24 hours.",
        "Roll the dough into balls.",
        "Place the balls onto trays with non-stick paper.",
        "Possible addition: place an almond on the cookie ball as a finishing touch",
        "Leave in the oven at 150ºC for 30 minutes.",
        "Store in a sealed container in a dry place. Can be stored for up to 6 months without losing quality.",
      ],
      graddSauce: [
        "50 gram smör",
        "3 dl vispgrädde",
        "1 msk soja",
        "2 tsk Worcestershiresås",
        "1 msk vetemjöl",
        "timjan",
        "Köttfond",
        "Salt och peppar",
      ],
      graddSauceHowTo: [
        "Smält smör i en kastrull och fortsätt fräsa under ständig omrörning tills det är gyllenbrunt och doftar nötigt.",
        "Vispa ner mjöl och lite av grädden till en tjock stuvning. Tillsätt resterande grädde, lite i taget, under vispning. Tillsätt örtkryddor (och ev steksky från köttbullarna).",
        "Låt sjuda på låg värme minst 10 min men gärna längre. Rör då och då. Tillsätt sKöttfond, soja och worcestersås. Låt koka ytterligare 5 min. Smaka av med salt och peppar.",
      ],
        choclateEspressoMartinti: [
        "6 cl kallt starkt kaffe eller espresso",
        "3 cl vodka",
        "3 cl kaffelikör",
        "1,5 cl choklad sockerlag (för recept se efterrätt)",
        "1 nypa flingsalt",
      ],
      choclateEspressoMartintiHowTo: [
        "Blanda alla ingredienser i en shaker med is och skaka våldsamt",
        "Häll upp i ett glas, toppa gärna med lite riven choklad och servera",
      ],
      tomCollins: [
        "5 cl gin(Gärna old tom Gin)",
        "3 cl citron juice",
        "2 cl sockerlag",
        "Sodavatten",
      ],
      tomCollinsHowTo: [
        "Blanda alla ingredienser förutom sodavatten i en shaker med is och skaka våldsamt",
        "Häll shaker innehållet och sodavattnet i ett high ball glas med is i samtidigt så det blandar sig",
        "Toppa gärna med ett inlagt körsbär eller en citron skiva",
      ],
      choclateSyrap: [
        "1 dl strösocker",
        "1 dl vatten",
        "3 msk kakao",
        "1 tsk vaniljsocker",
      ],
      choclateSyrapHowTo: [
        "Blanda alla ingredienser i en kastrull och ställ på låg värme",
        "Rör då och då och vänta tills sockret löst upp sig",
        "Tillsätt vaniljsocker, låt svalna",
      ],
      pepparSauce: [
        "1 tsk svartpeppar hel",
        "1 tsk rosepeppar",
        "1 tsk grönpeppar",
        "2 schalottenlökar",
        "1 msk smör",
        "1 msk tomatpure",
        "2 dl vispgrädde",
        "1 dl vatten",
        "1 msk ljus soja",
        "1 msk kalvfond",
      ],
      pepparSauceHowTo: [
        "Stöt pepparkornen i en mortel",
        "Skala och finhacka lök",
        "Fräs peppar i smör",
        "Tillsätt tomatpure och lök och låt fräsa tills tomatpuren har \"smält\"",
        "Tillsätt vatten, grädde, soja och fond",
        "Sjud såsen utan lock i ca 10 min",
        "Blanda majsstärkelse och 2 msk vatten och rör ner i såsen",
        "Koka upp och krydda med salt",
      ],
      potatoCake: [
        "800 g potatis",
        "4 dl vispgrädde",
        "100 g färskost tex philadelphia",
        "2 ägg",
        "1 msk smör",
        "vitlök",
        "salt",
        "peppar",
        "lökpulver",
        "färsk rosmarin",
      ],
      potatoCakeHowTo: [
        "Skala och skiva potatisen tunnt",
        "Läg ett bakplåtspapper i en ugnsform",
        "Vispa ihop ägg, grädde, färskost, vitlök, salt, peppar och lökpulver",
        "Fördelsa ut potatisen tätt i ugnsformen och häll på sås och hackad rosmarin och bygg upp det i lager. ",
        "Ställ in formen i ugnen i 225 c grader i ungefär ca 45 minuter",
      ],
    };
  },
  components: {},
};
</script>

<style scoped>
.center {
  text-align: center;
}
.img {
  height: 37em;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.padding {
  padding: 0.5em;
}
.title {
  text-align: center;
  padding: 1em;
}
.buttonWidth {
  width: 20em;
}
.is-selected label {
  text-decoration: line-through;
}
</style>