<template>
    <div>
      <div>
<nav class="navbar" role="navigation" aria-label="main navigation" >
  <div class="navbar-brand">


    <a role="button" class="navbar-burger burger"  aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onclick="document.querySelector('.navbar-menu').classList.toggle('is-active');">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start">
      <a class="navbar-item" @click="setUserChoice">
      </a>
    </div>
  </div>
</nav>

        </div>
        <app-start v-if="userChoice === '| Välkommen |'"></app-start>


    </div>
</template>
<script>
    import Start from './start.vue'



    export default {
        methods: { 
            setUserChoice(choice) {
                this.userChoice = choice.target.innerText
            }
        }, 
        data: function(){
            return {
                userChoice: "| Välkommen |",

            }
        },
        components: {
         'app-start': Start,
        }
        
    }
</script>
<style scoped>
  #id {
    width: 50%;
    margin: auto;
  }
    .id {
    margin: auto;
    width: 20%;
    display: grid;
    grid-template-columns: repeat(4fr);
    grid-template-rows: 4;
  }
    .navbar {
      background-color:white
    }


  img {
    max-width: 100%;
  }
  div{
         font-family:Verdana, Geneva, Tahoma, sans-serif;
  }

  
</style>