import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Header from './components/shared/Header.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bulma/css/bulma.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.component('app-header', Header)


export const eventBus = new Vue({
  data: {
  }, 
  methods: {
  }
})


new Vue({
  render: h => h(App),
}).$mount('#app');



Vue.filter('two_digits', function (value) {
  if(value.toString().length <= 1)
  {
      return "0"+value.toString();
  }
  return value.toString();
});