<template>
    <div id=logo>
    </div>
</template>
<script>
    export default {
        
    }
</script>
<style scoped>
  #id {
    width: 50%;
    margin: auto;
  }

  img {
    max-width: 100%;
  }
</style>